import React, { Component } from "react";
import { Link } from "react-router-dom";
import { withAuth } from "@okta/okta-react";
import AppBar from "@material-ui/core/AppBar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import Toolbar from "@material-ui/core/Toolbar";
import Switch from "@material-ui/core/Switch";
import { Button, Select, FormControl, MenuItem } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import moment from "moment-timezone";
import axios from "axios";
import { v4 as uuid } from "uuid";
import { firstBy } from "thenby";

import siqLogo from "./siqLogo.png";
import { GamePeriodTable, TeamTable } from "./components/Table";
import LineupTable from "./components/LineupTable";
import BroadCaster from "./components/BroadCaster";
import NFLLineupTable from "./components/NFLLineupTable";
import NFLRankingTable from "./components/NFLRankingTable";
import MLBLineupTable from "./components/MLBLineupTable";
import NHLLineupTable from "./components/NHLLineupTable";
import MLBCheckList from "./components/MLBCheckList";
import CheckList from "./components/CheckList";
import ControlLogger from "./components/ControlLogger";
import InjuryTables from "./components/InjuryTables";
import TraderList from "./components/TraderList";
import GameStatusControl from "./components/GameStatusControl";
import LabelDial from "./components/LabelDial";
import { colorPicker, TeamLogo } from "./utilities";
import { db } from "./firebase";
import "./App.css";
import * as E2EASelectors from "./e2ea/e2ea_selectors.json";

var slacks_channels = [];
var MY_SLACK_WEBHOOK_URLS = [
  // 'https://hooks.slack.com/services/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',
  // 'https://hooks.slack.com/services/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',
  // 'https://hooks.slack.com/services/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'
  "https://hooks.slack.com/services/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",
];
MY_SLACK_WEBHOOK_URLS.forEach((url) => {
  var slack = require("slack-notify")(url);
  slacks_channels.push(slack);
});

var trader_slack = require("slack-notify")(
  "https://hooks.slack.com/services/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"
);

const drawerWidth = 250;
const containerHeight = window.innerHeight - 64 + "px";
const useStyles = makeStyles((theme) => ({
  typoFont: {
    fontSize: "16px",
    fontStyle: "italic",
  },
  root: {
    display: "flex",
  },
  listRoot: {
    width: "100%",
    maxWidth: 230,
  },
  external_link: {
    paddingLeft: theme.spacing(2),
    textDecoration: "none",
    color: "#fff",
  },
  external_link_parent: {
    paddingLeft: theme.spacing(0),
    textDecoration: "none",
  },
  external_link_child: {
    paddingLeft: theme.spacing(6),
    textDecoration: "none",
  },
  doc_subtitle: {
    paddingLeft: theme.spacing(3),
    textDecoration: "none",
  },
  subtitle_child: {
    paddingLeft: theme.spacing(7),
    textDecoration: "none",
  },
  doc_external_link: {
    textDecoration: "none !important",
    color: "#E6AF2A",
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  drawer: {
    [theme.breakpoints.up("md")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    [theme.breakpoints.up("md")]: {
      width: `calc(100%)`,
      marginLeft: drawerWidth,
      zIndex: theme.zIndex.drawer + 1,
    },
    backgroundColor: "#333",
    color: "#fff",
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
    background: "#202020",
    color: "#fff",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    background: "#202020",
    color: "#fff",
  },
}));

function GameListBlock(props) {
  return (
    <div style={{ paddingTop: "6px" }}>
      <div style={{ fontSize: "initial" }}>
        <div
          style={{ margin: "2px 8px", width: "25px", display: "inline-block" }}
        ></div>
        <div style={{ padding: "2px 0px", display: "inline-block" }}>
          {props.game.away.abbr}
        </div>
        <div
          style={{
            padding: "2px 10px",
            float: "right",
            display: "inline-block",
          }}
        >
          {moment(props.game.scheduled).format("ddd H:mm")}
        </div>
      </div>
      <div style={{ fontSize: "initial" }}>
        <div
          style={{ margin: "2px 8px", width: "25px", display: "inline-block" }}
        ></div>
        <div style={{ padding: "2px 0px", display: "inline-block" }}>
          {props.game.home.abbr}
        </div>
        <div
          style={{
            padding: "2px 10px",
            float: "right",
            display: "inline-block",
          }}
        >
          {moment().tz(moment.tz.guess()).format("z")}
        </div>
      </div>
    </div>
  );
}

function LeagueGameList(props) {
  if (props.games) {
    props.games.sort(firstBy("scheduled").thenBy("rotation_id"));
  }
  if (props.selectedGame) {
    return (
      <div>
        <div style={{ margin: "10px 10px", fontWeight: "bold" }}>
          {props.league.name}
        </div>
        {props.games.map((game) => {
          if (game.id === props.selectedGame.id) {
            return (
              <div
                key={game.id}
                style={{
                  border: "1px solid #E4AE3A",
                  color: "#E4AE3A",
                  height: "65px",
                  margin: "10px 10px",
                  fontWeight: "bold",
                }}
              >
                <GameListBlock game={props.selectedGame} />
              </div>
            );
          } else {
            return (
              <Link
                key={game.id}
                to={`/${props.league.name}/${moment(game.scheduled).format(
                  "YYYY-MM-DD"
                )}/game/${game.id}`}
                style={{ color: "black", verticalAlign: "middle" }}
              >
                <div
                  style={{
                    border: "1px solid  #4e4e4e",
                    color: "#dedede",
                    height: "65px",
                    margin: "10px 10px",
                    fontWeight: "bold",
                  }}
                >
                  <GameListBlock game={game} />
                </div>
              </Link>
            );
          }
        })}
      </div>
    );
  } else {
    return null;
  }
}

function settlement_sender(league, game_id, period, source, config) {
  var type = league + '_settlement';
  var data = {};
  data.game_id = game_id;
  data.period = period;
  data.type = type
  data.source = source;
  data.reverse = false;
  data.lineup = true;

  var url;
  url = 'https://consoleproxy.sportsiq.ai/siqTools/publishToPubsub/?comp_id=' + game_id + '&topic=TEAM_SETTLEMENT&type=' + type + '&source='+ source + '&reload=false';
  url = url + '&league=' + league + '&email=dashboard@sportsiq.ai' + '&data=' + JSON.stringify(data);


  // var url =
  //   "https://us-central1-lunar-brace-213823.cloudfunctions.net/period_settlement_api?lineup=true&source=" +
  //   source +
  //   "&game_id=" +
  //   game_id +
  //   "&period=" +
  //   period +
  //   "&league=" +
  //   league;
  // if (league) {
  //   axios
  //     .get(url)
  //     .then((res) => {
  //       if (res.status !== 200) {
  //         console.log(res.data);
  //       } else {
  //         console.log(res.data);
  //       }
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // }

    if (league) {
    axios
      .get(url, config)
      .then((res) => {
        if (res.status !== 200) {
          console.log(res.data);
        } else {
          console.log(res.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
}

// Dashboard.propTypes = {
//     container: PropTypes.any
//   };

export default withAuth(
  class GameDetail extends Component {
    constructor(props) {
      super(props);

      this.state = {
        authenticated: null,
        source1: true,
        source2: false,
        source3: false,
        away_lineup: [],
        home_lineup: [],
        cache_away_lineup: [],
        cache_home_lineup: [],
        away_rankings: [],
        home_rankings: [],
        loading: true,
        broadcastMsg: "",
        mute: false,
        email: "null@null.ai",
        settlement_period: "CG",
        settlement_period_t: "",
        shading_market: "",
        away_injury: [],
        home_injury: [],
        accessToken: "",
      };
      this.Dashboard = this.Dashboard.bind(this);
      this.GameDrawer = this.GameDrawer.bind(this);
      this.ScoreBoard = this.ScoreBoard.bind(this);
      this.ScoreSwitch = this.ScoreSwitch.bind(this);
      this.updateHandler = this.updateHandler.bind(this);
      this.MLBupdateHandler = this.MLBupdateHandler.bind(this);
      this.submitLineup = this.submitLineup.bind(this);
      this.MLBsubmitLineup = this.MLBsubmitLineup.bind(this);
      this.pullLineup = this.pullLineup.bind(this);
      this.pullCachedLineup = this.pullCachedLineup.bind(this);
      this.pullLastGameLineup = this.pullLastGameLineup.bind(this);
      this.clearGameLineup = this.clearGameLineup.bind(this);
      this.publishPostLineup = this.publishPostLineup.bind(this);
      this.MuteSwitch = this.MuteSwitch.bind(this);
      this.TestLineup = this.TestLineup.bind(this);
      this.RePublisher = this.RePublisher.bind(this);
      this.SettlementSelector = this.SettlementSelector.bind(this);
      this.ShadingSelector = this.ShadingSelector.bind(this);
      this.pullRankings = this.pullRankings.bind(this);
      this.new_lineup_post_saver = this.new_lineup_post_saver.bind(this);
      this.call_player_suspension_api = this.call_player_suspension_api.bind(this);
    }

    checkAuthentication = async (flag) => {
      const authenticated = await this.props.auth.isAuthenticated();
      if (authenticated !== this.state.authenticated) {
        this.setState({
          authenticated,
        });
        var idToken = JSON.parse(localStorage.getItem("okta-token-storage"));
        this.setState({ accessToken: idToken.accessToken.accessToken });
        if (flag) {
          this.pullInjury(this.props.game.match.params.id);
        }
        var user_email = "null@siq.ai";
        if (idToken && idToken.idToken) {
          user_email = idToken.idToken.claims.email;
        }
        this.props.authenticate(user_email);
        this.setState({ email: user_email });
        this.props.messageTrigger(
          "game_bootstrap",
          this.props.game.match.params.id,
          this.props.game.match.params.date
        );
      }
    };

    pullRankings(game_id, team_id, away_home) {
      var url =
        "https://us-central1-lunar-brace-213823.cloudfunctions.net/get_rankings_nfl?game_id=" +
        game_id +
        "&team_id=" +
        team_id;
      axios
        .get(url)
        .then((res) => {
          var fetchedData = res.data;
          if (Array.isArray(fetchedData)) {
            if (away_home === "away") {
              this.setState({ away_rankings: fetchedData });
            }
            if (away_home === "home") {
              this.setState({ home_rankings: fetchedData });
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }

    SettlementSelector(props) {
      var periods = [];
      var periods_t = ["", "T"];
      var league = props.league ? props.league.toLowerCase() : "";
      var game_id = "";
      var scheduled = true;
      if (props.game) {
        game_id = props.game.id ? props.game.id : "";
      }
      if (
        props.game &&
        props.game.status &&
        props.game.status === "scheduled"
      ) {
        scheduled = true;
        // scheduled = false
        if (this.state.settlement_period === "CANCELLED") {
          scheduled = false;
        }
      } else {
        scheduled = false;
      }
      if (props.league === "MLB") {
        periods = [
          "CG",
          "I1",
          "I2",
          "I3",
          "I4",
          "I5",
          "I7",
          "I8",
          "I9",
          "CANCELLED",
        ];
      } else if (props.league === "NHL") {
        periods = ["CG", "P1", "P2", "P3", "CANCELLED"];
      } else {
        periods = ["CG", "Q1", "Q2", "Q3", "Q4", "CLOSED", "CANCELLED"];
      }
      return (
        <div>
          <div>Period to settle</div>
          <FormControl style={{ marginRight: "15px" }}>
            <Select
              value={this.state.settlement_period}
              onChange={(e) => {
                this.setState({ settlement_period: e.target.value });
              }}
              displayEmpty
              inputProps={{ "aria-label": "Without label" }}
            >
              {periods.map((period) => {
                return (
                  <MenuItem key={period} value={period}>
                    {period}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <Button
            onClick={() => {
              const config = {
                headers: {
                  Authorization: `Bearer ${this.state.accessToken}`,
                },
              };
              if (league === "nfl") {
                settlement_sender(
                  league,
                  game_id,
                  this.state.settlement_period,
                  "betgenius",
                  config
                );
                // settlement_sender(league, game_id, this.state.settlement_period, 'sportradar')
              } else {
                settlement_sender(
                  league,
                  game_id,
                  this.state.settlement_period,
                  "sportradar",
                  config
                );
              }
            }}
            // disabled={scheduled}
            color="primary"
          >
            Settle
          </Button>
          {props.league === "MLB" ? (
            <div>
              <FormControl style={{ marginRight: "15px" }}>
                <Select
                  value={this.state.settlement_period_t}
                  onChange={(e) => {
                    this.setState({ settlement_period_t: e.target.value });
                  }}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                >
                  {periods_t.map((period) => {
                    return (
                      <MenuItem key={period} value={period}>
                        {period}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              <Button
                onClick={() => {
                  const config = {
                    headers: {
                      Authorization: `Bearer ${this.state.accessToken}`,
                    },
                  };
                  settlement_sender(
                    league,
                    game_id,
                    this.state.settlement_period_t,
                    "sportradar",
                    config
                  );
                }}
                // disabled={scheduled}
                color="primary"
              >
                Settle
              </Button>
            </div>
          ) : null}
        </div>
      );
    }

    ShadingSelector(props) {
      var markets = [];
      if (props.league === "MLB") {
        markets = 
          [
            "",
            "Home Runs, 40667, H4",
            "Total Bases, 40682, T_O",
            "Hits, 40663, TH",
            "Hits + Runs + RBI, 40685, RHR",
            "RBI, 40684, RBI",
            "Stolen Bases, 40686, SB",
            "Runs, 40664, R",
            "Walks, 40666, W",
            "Doubles, 40669, H2",
            "Strikeouts, 40665, SO",
            "Singles, 40668, H1",
            "Triples, 40670, H3",
            "Strikeouts Thrown, 40673, SO_P",
            "Outs, 40681, P_O",
            "Win Probability, 40690, WP_P",
            "Walks, 40674, W_P",
            "Hits Allowed, 40671, TH_P",
            "Earned Runs Allowed, 40687, ER_P"
          ]
        ;
      } else if (props.league === "NBA" || props.league === "WNBA") {
        markets = 
          [
            "",
            "Points, 20313, P",
            "Assists, 20315, A",
            "Rebounds, 20314, R",
            "Three Point Made, 20319, F3M",
            "Points + Rebounds, 20401, PR",
            "Points + Assists, 20402, PA",
            "Points + Rebounds + Assists, 20403, PRA",
            "Rebounds + Assist, 20404, RA",
            "Points + Rebounds + Blocks, 20405, PRB",
            "Points + Assists + Steals, 20406, PAS",
            "Points + Rebounds + Assists + Blocks + Steals, 20407, PRABS",
            "Two Point Made, 20321, F2M",
            "Blocks + Steals, 20413, SB",
            "Blocks, 20317, B",
            "Steals, 20316, S"
          ];
      } else if (props.league === "NHL") {
        markets = [
          "",
          "Assists, 30315, A",
          "Blocks, 30317, B",
          "Goals, 30346, G",
          "Goal Against, 30345, GA",
          "Points, 30313, P",
          "Power Play Points, 30318, PPP",
          "Shots, 30314, S",
          "Shutout, 30349, ST",
          "Saves, 30348, SV"
        ]
      } else if (props.league === "NFL") {
        markets = [
            "",
            "Passing Yards, 10335, QPY",
            "Pass Completions, 10334, QPC",
            "Pass Attempts, 10333, QPA",
            "Passing TD, 10331, QTDP",
            "Interceptions Thrown, 10354, QBI",
            "Passing + Rushing Yards, 10344, QPYR",
            "Rush Attempts, 10337, RA",
            "Rushing + Receiving Yards, 10346, RURY",
            "Receptions, 10342, RRC",
            "Receiving Yards, 10343, RRY",
            "Rushing Yards, 10336, RY",
            "FG Made, 10339, FGM",
            "FG Attempted, 10338, FGA",
            "PAT Made, 10350, PAT1",
            "Kicking Points, 10351, KTP",
            "Tackles, 10352, TKO",
            "Assisted Tackles, 10353, ASO",
            "Tackles + Assisted Tackles, 10347, TK",
            "Sacks, 10348, SCK",
            "Interceptions, 10349, DFI",
            "Longest Passing Completion, 10355, LPC",
            "Longest Reception, 10356, LRRY",
            "Longest Rush, 10357, LRY",
        ];
      } else if (props.league === "CFB") {
        markets = [
          "",
          "Passing Yards, 10335, QPY",
          "Passing TD, 10331, QTDP",
          "Interceptions Thrown, 10354, QBI",
          "Rushing Yards, 10336, RY",
          "Receiving Yards, 10343, RRY",
        ];
      }
      return (
        <div>
          <div>Market to send DEFAULT Shading</div>
          <FormControl style={{ marginRight: "15px" }}>
            <Select
              value={this.state.shading_market}
              onChange={(e) => {
                this.setState({ shading_market: e.target.value });
              }}
              displayEmpty
              inputProps={{ "aria-label": "Without label" }}
            >
              {markets.map((market) => {
                return (
                  <MenuItem key={market} value={market}>
                    {market}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </div>
      );
    }

    submitLineup(game_id, league) {
      var home_lineup = JSON.parse(JSON.stringify(this.state.home_lineup));
      var away_lineup = JSON.parse(JSON.stringify(this.state.away_lineup));
      var newLineup = [];
      home_lineup.forEach((player) => {
        delete player.name;
        delete player.team;
        delete player.order;
        delete player.tableData;
        if (
          player.starPlayer === true ||
          player.active === true ||
          player.questionable === true ||
          player.probable === true ||
          player.block === true
        ) {
          newLineup.push(player);
        }
      });
      away_lineup.forEach((player) => {
        delete player.name;
        delete player.team;
        delete player.order;
        delete player.tableData;
        if (
          player.starPlayer === true ||
          player.active === true ||
          player.questionable === true ||
          player.probable === true ||
          player.block === true
        ) {
          newLineup.push(player);
        }
      });
      var command = {};
      command.command = "game_lineups";
      command.game_id = game_id;
      var data = {};
      data.lineups = newLineup;
      command.data = data;
      // console.log(JSON.stringify(command));
      // this.props.sendCommand(command);
      this.new_lineup_post_saver(
        command,
        this.state.cache_away_lineup,
        this.state.cache_home_lineup,
        this.state.home_lineup,
        this.state.away_lineup,
        league
      );
    }

    new_lineup_post_saver(
      data,
      cache_away_lineup,
      cache_home_lineup,
      home_lineup,
      away_lineup,
      league
    ) {
      // console.log(league);
      // console.log(cache_away_lineup);
      var url =
        "https://dashboard.sportsiq.ai:8443/sportsiq-websocket/sendCommand/" +
        JSON.stringify(data);
      // console.log(url);
      // console.log(data);
      axios
        .get(url)
        .then((res) => {
          if (res.status !== 200) {
            data.error = res;
          } else {
            if (data && data.data && data.data.lineups) {
              var old_lineups = [cache_away_lineup, cache_home_lineup];
              var new_active_players = [];
              data.data.lineups.forEach((new_player) => {
                if (new_player.active === true) {
                  new_active_players.push(new_player.playerId);
                }
              });

              old_lineups.forEach((team) => {
                team.forEach((player) => {
                  if (!new_active_players.includes(player.playerId)) {
                    if (
                      player.active === true ||
                      player.probable === true ||
                      player.questionable === true
                    ) {
                      const config = {
                        headers: {
                          Authorization: `Bearer ${this.state.accessToken}`,
                        },
                      };
                      this.call_player_suspension_api(
                        player.gameId,
                        player.playerId,
                        league,
                        config
                      );
                    }
                  }
                });
              });
            }
          }
        })
        .catch((error) => {
          data.error = error;
          console.log(error);
        });
    }

    call_player_suspension_api(game_id, player_id, league, config) {
      var adjustment_url =
        "https://consoleproxy.sportsiq.ai/siqTools/publishToPubsub/?topic=siq.control.decouple&type=playerSuspension&source=admin&reload=false&email=lineup_tool_proxy@sportsiq.ai&league=" +
        league;
      adjustment_url = adjustment_url + "&comp_id=" + game_id + "&data=";
      var adjData = {
        release: false,
        player_id: player_id,
      };
      axios
        .get(adjustment_url + JSON.stringify(adjData), config)
        .then(function (response) {})
        .catch(function (err) {
          console.error(err);
        });
    }

    MLBsubmitLineup(game_id, league) {
      var home_lineup = JSON.parse(JSON.stringify(this.state.home_lineup));
      var away_lineup = JSON.parse(JSON.stringify(this.state.away_lineup));
      var newLineup = [];
      home_lineup.forEach((player) => {
        // if (player.changed === true){
        newLineup.push(player);
        // }
      });
      away_lineup.forEach((player) => {
        // if (player.changed === true){
        newLineup.push(player);
        // }
      });

      var updatedStatusPlayers = [];
      newLineup.forEach((player) => {
        if (
          player.starPlayer === true ||
          player.startingPitcher === true ||
          (player.battingOrder !== 99 && player.battingOrder !== 100)
        ) {
          player.active = true;
        } else {
          player.active = false;
        }
        updatedStatusPlayers.push(player);
      });

      var activePlayers = [];
      updatedStatusPlayers.forEach((player) => {
        if (player.active === true) {
          activePlayers.push(player);
        }
      });

      var ParsedPlayer = JSON.parse(JSON.stringify(activePlayers));
      ParsedPlayer.forEach((player) => {
        var newOrder;
        if (player.battingOrder === 10) {
          newOrder = 0;
        } else if (player.battingOrder === 99) {
          newOrder = -1;
        } else {
          newOrder = player.battingOrder;
        }
        player.battingOrder = newOrder;
        delete player.changed;
        delete player.name;
        delete player.position;
        delete player.tableData;
      });

      var command = {};
      command.command = "game_lineups";
      command.game_id = game_id;
      var data = {};
      data.lineups = ParsedPlayer;
      command.data = data;
      // console.log(JSON.stringify(command));
      // this.props.sendCommand(command);
      this.new_lineup_post_saver(
        command,
        this.state.cache_away_lineup,
        this.state.cache_home_lineup,
        this.state.home_lineup,
        this.state.away_lineup,
        league
      );
    }

    publishPostLineup(command) {
      command.email = this.state.email;
      var url =
        "https://dashboard.sportsiq.ai:8443/sportsiq-websocket/sendCommand/" +
        JSON.stringify(command);
      // console.log(url);
      axios
        .get(url)
        .then((res) => {
          if (res.status !== 200) {
            command.error = res;
          }
        })
        .catch((error) => {
          command.error = error;
          console.log(error);
        });
    }

    pullCachedLineup(team_id, away, league) {
      var promise = db.collection("TEAM_LINEUP_CACHE").doc(team_id).get();
      promise
        .then((snapshot) => {
          // handle the document snapshot here
          if (snapshot.data()) {
            var data = snapshot.data();
            var players = data.players;
            if (players.length > 0) {
              var player_id_list = [];
              var player_active_list = [];
              var player_out_list = [];
              var player_probable_list = [];
              var star_player_list = [];
              players.forEach((player) => {
                player_id_list.push(player.playerId);
                if (player.questionable === true) {
                  player_out_list.push(player.playerId);
                } else if (player.probable && player.probable === true) {
                  player_probable_list.push(player.playerId);
                } else {
                  player_active_list.push(player.playerId);
                }
                if (player.starPlayer === true) {
                  star_player_list.push(player.playerId);
                }
              });
              var currentLineup;
              if (away) {
                currentLineup = JSON.parse(
                  JSON.stringify(this.state.away_lineup)
                );
                currentLineup.forEach((player) => {
                  if (star_player_list.includes(player.playerId)) {
                    player.starPlayer = true;
                  } else {
                    player.starPlayer = false;
                  }
                  if (league === "MLB") {
                    if (player_id_list.includes(player.playerId)) {
                      // player.battingOrder = 10;
                      players.forEach((cached_player) => {
                        if (cached_player.playerId === player.playerId) {
                          if (cached_player.battingOrder === 0) {
                            // player.battingOrder = 10
                          } else {
                            player.battingOrder = cached_player.battingOrder;
                          }
                          // player.startingPitcher = cached_player.startingPitcher
                        }
                      });
                    }
                  } else if (
                    league === "NFL" ||
                    league === "NHL" ||
                    league === "NBA" ||
                    league === "WNBA" ||
                    league === "CFB"
                  ) {
                    if (player_active_list.includes(player.playerId)) {
                      player.active = true;
                      if (league !== "NHL") {
                        player.probable = false;
                      }
                      player.order = 1;
                    } else if (player_probable_list.includes(player.playerId)) {
                      if (league !== "NHL") {
                        player.active = false;
                      } else {
                        player.active = true;
                      }
                      player.probable = true;
                      player.order = 2;
                    } else if (player_out_list.includes(player.playerId)) {
                      player.active = false;
                      player.probable = false;
                      player.questionable = true;
                      player.order = 3;
                    } else {
                      player.active = false;
                      player.probable = false;
                      player.order = 4;
                    }
                  }
                });
                this.setState({ away_lineup: currentLineup });
              } else {
                currentLineup = JSON.parse(
                  JSON.stringify(this.state.home_lineup)
                );
                currentLineup.forEach((player) => {
                  if (star_player_list.includes(player.playerId)) {
                    player.starPlayer = true;
                  } else {
                    player.starPlayer = false;
                  }
                  if (league === "MLB") {
                    if (player_id_list.includes(player.playerId)) {
                      // player.battingOrder = 10;
                      players.forEach((cached_player) => {
                        if (cached_player.playerId === player.playerId) {
                          if (cached_player.battingOrder === 0) {
                            // player.battingOrder = 10
                          } else {
                            player.battingOrder = cached_player.battingOrder;
                          }
                          // player.startingPitcher = cached_player.startingPitcher
                        }
                      });
                    }
                  } else if (
                    league === "NFL" ||
                    league === "NHL" ||
                    league === "NBA" ||
                    league === "WNBA" ||
                    league === "CFB"
                  ) {
                    if (player_active_list.includes(player.playerId)) {
                      player.active = true;
                      if (league !== "NHL") {
                        player.probable = false;
                      }
                      player.order = 1;
                    } else if (player_probable_list.includes(player.playerId)) {
                      if (league !== "NHL") {
                        player.active = false;
                      } else {
                        player.active = true;
                      }
                      player.probable = true;
                      player.order = 2;
                    } else if (player_out_list.includes(player.playerId)) {
                      player.active = false;
                      player.probable = false;
                      player.questionable = true;
                      player.order = 3;
                    } else {
                      player.active = false;
                      player.probable = false;
                      player.order = 4;
                    }
                  }
                });
                this.setState({ home_lineup: currentLineup });
              }
            }
          }
        })
        .catch((error) => {
          // <---- you are missing this part
          console.log(error);
        });
    }

    pullLastGameLineup(game_id, team_id, if_away) {
      // console.log(game_id, team_id, if_away);
      // console.log(this.state.away_lineup);
      // console.log(this.state.home_lineup);
      const config = {
        headers: {
          Authorization: `Bearer ${this.state.accessToken}`,
        },
      };
      var url =
        "https://consoleproxy.sportsiq.ai/siqTools/game/previousLineup?gameId=" +
        game_id +
        "&teamId=" +
        team_id;
      axios
        .get(url, config)
        .then((res) => {
          var fetchedData = res.data;
          var game_lineup = fetchedData.game_lineup;
          var teams = game_lineup ? game_lineup.teams : [];
          var team_lineup = [];
          if (teams.length > 0) {
            team_lineup = teams[0];
          }
          var players = [];
          if (team_lineup.players && team_lineup.players.length > 0) {
            players = team_lineup.players;
          }
          // console.log(players);

          var new_lineup;
          if (if_away) {
            new_lineup = this.state.away_lineup;
          } else {
            new_lineup = this.state.home_lineup;
          }
          players.forEach((player) => {
            new_lineup.forEach((old_player) => {
              if (player.playerId === old_player.playerId) {
                old_player.active = player.active;
                old_player.probable = player.probable;
                old_player.questionable = player.questionable;
                old_player.block = player.block;
                old_player.starPlayer = player.starPlayer;
                if (old_player.active === true) {
                  old_player.order = 1;
                }
                if (old_player.probable === true) {
                  old_player.order = 2;
                }
                if (old_player.questionable === true) {
                  old_player.order = 3;
                }
                old_player.order =
                  old_player.position === "G" ? 0 : old_player.order;
                if (player.battingOrder || player.battingOrder === 0) {
                  if (player.battingOrder === 0) {
                    if (player.startingPitcher === false) {
                      old_player.battingOrder = 10;
                    }
                  } else {
                    old_player.battingOrder = player.battingOrder;
                  }
                  // old_player.startingPitcher = player.startingPitcher;
                }
              }
            });
          });
          // console.log(new_lineup);
          if (if_away) {
            this.setState({ away_lineup: new_lineup });
            this.setState({ cache_away_lineup: new_lineup });
          } else {
            this.setState({ home_lineup: new_lineup });
            this.setState({ cache_home_lineup: new_lineup });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }

    clearGameLineup() {
      var away_new_lineup = this.state.away_lineup;
      var home_new_lineup = this.state.home_lineup;

      var lineups = [away_new_lineup, home_new_lineup];
      lineups.forEach((lineup) => {
        lineup.forEach((old_player) => {
          old_player.active = false;
          old_player.probable = false;
          old_player.questionable = false;
          old_player.starPlayer = false;
          old_player.startingPitcher = false;
          if (old_player.active === true) {
            old_player.order = 1;
          }
          if (old_player.probable === true) {
            old_player.order = 2;
          }
          if (old_player.questionable === true) {
            old_player.order = 3;
          }
          old_player.order = old_player.position === "G" ? 0 : old_player.order;
          old_player.battingOrder = -1;
        });
      });
      this.setState({ away_lineup: away_new_lineup });
      this.setState({ home_lineup: home_new_lineup });
    }

    pullLineup(game_id, league) {
      var url;
      if (league === "NBA") {
        url =
          "https://us-central1-lunar-brace-213823.cloudfunctions.net/sportsiq_http_proxy/leagueLineup?league=nba&gameId=";
      } else if (league === "WNBA") {
        url =
          "https://us-central1-lunar-brace-213823.cloudfunctions.net/sportsiq_http_proxy/leagueLineup?league=wnba&gameId=";
      }
      const player_api = url + game_id;
      axios.get(player_api).then((res) => {
        var fetchedData = res.data;
        var awayPlayers = [];
        var homePlayers = [];
        var parsedPlayers = [];
        fetchedData.forEach((player) => {
          var parsedPlayer = {
            playerId: player.playerId,
            name: player.firstName + " " + player.lastName,
            sr_player_id: player.srPlayerId,
            team: player.teamName,
            side: player.side,
            active: player.active,
            starPlayer: player.starPlayer,
            gameId: player.gameId,
            order: player.active ? 0 : 1,
          };
          parsedPlayers.push(parsedPlayer);
        });

        parsedPlayers.forEach((player) => {
          if (player.side === "home") {
            homePlayers.push(player);
          } else {
            awayPlayers.push(player);
          }
        });

        this.setState({ away_lineup: awayPlayers });
        this.setState({ home_lineup: homePlayers });
      });
    }

    updateHandler(oldData, newData, title, league, status) {
      if (oldData.starPlayer === newData.starPlayer) {
        var oldStatus = "No Status ===>";
        if (oldData.probable === true) {
          oldStatus = "Questionable ===>";
        }
        if (oldData.active === true) {
          oldStatus = "Active ===>";
        }
        if (oldData.questionable === true) {
          oldStatus = "Out ===>";
        }

        var newStatus;
        if (newData.probable === true) {
          newStatus = " Questionable  :question:";
        }
        if (newData.active === true) {
          newStatus = " Active  :white_check_mark:";
        }
        if (newData.questionable === true) {
          newStatus = " Out  :x:";
        }

        var player_name = newData.name;
        var date = "";
        if (newData.starPlayer === true) {
          date +=
            ":star2: :star2: :star2: Star Player :star2: :star2: :star2:" +
            "\n";
        }
        var team_name = newData.team;
        date += document.location.href.split("/")[4].substring(5) + " || ";
        var msg =
          date +
          league +
          " - " +
          team_name +
          "\n" +
          player_name +
          "\n" +
          oldStatus +
          newStatus +
          "\n";
        msg += this.state.email;

        if (newStatus && !this.state.mute) {
          if (status === "inprogress") {
            trader_slack.bug(msg);
          }
          slacks_channels.forEach((channel) => {
            channel.bug(msg);
          });
        }
      }

      if (oldData) {
        this.setState((prevState) => {
          if (title.includes("Away Lineup")) {
            var away_lineup = [...prevState.away_lineup];
            away_lineup[away_lineup.indexOf(oldData)] = newData;
            return { ...prevState, away_lineup };
          } else {
            var home_lineup = [...prevState.home_lineup];
            home_lineup[home_lineup.indexOf(oldData)] = newData;
            return { ...prevState, home_lineup };
          }
        });
      }
    }

    MLBupdateHandler(oldData, newData, pitcherChange, title, status) {
      if (pitcherChange === true) {
        var player_name = newData.name;
        var date = "";
        if (newData.starPlayer === true) {
          date +=
            ":star2: :star2: :star2: Star Player :star2: :star2: :star2:" +
            "\n";
        }
        var team_name = newData.team;
        date += document.location.href.split("/")[4].substring(5) + " || ";
        var msg = date + "MLB" + " - " + team_name + "\n";
        if (newData.startingPitcher === true) {
          msg +=
            "New Pitcher Announced :baseball: :baseball: :baseball: " + "\n";
        } else {
          msg += "Current Pitcher Out :x: :x: :x: " + "\n";
        }

        msg += player_name + "\n";
        msg += this.state.email;

        if (!this.state.mute) {
          if (status === "inprogress") {
            trader_slack.bug(msg);
          }
          slacks_channels.forEach((channel) => {
            channel.bug(msg);
          });
        }
      }

      if (oldData) {
        var new_lineup;
        if (title.includes("Away Lineup")) {
          new_lineup = this.state.away_lineup;
        } else {
          new_lineup = this.state.home_lineup;
        }
        new_lineup.forEach((player) => {
          if (player.playerId === newData.playerId) {
            player.battingOrder = newData.battingOrder;
            player.active = newData.active;
            player.starPlayer = newData.starPlayer;
            player.changed = newData.changed;
            player.startingPitcher = newData.startingPitcher;
            if (newData.startingPitcher === true) {
              player.changed = true;
            }
          } else {
            if (pitcherChange) {
              player.startingPitcher = false;
            }
          }
        });

        if (title.includes("Away Lineup")) {
          this.setState({ away_lineup: new_lineup });
        } else {
          this.setState({ home_lineup: new_lineup });
        }

        // this.setState((prevState) => {
        //     if (title.includes('Away Lineup')){
        //         var away_lineup = [...prevState.away_lineup];
        //         away_lineup[away_lineup.indexOf(oldData)] = newData;
        //         console.log('00', away_lineup)
        //         if (pitcherChange === true){
        //             count = 0;
        //             away_lineup.forEach(row => {
        //                 if (count !== away_lineup.indexOf(oldData)){
        //                     if (row.startingPitcher === true){
        //                         row.changed = true;
        //                     }
        //                     row.startingPitcher = false;
        //                 }
        //                 count ++;
        //             })
        //         }
        //         console.log(away_lineup);
        //         return { ...prevState, away_lineup };
        //     } else {
        //         var home_lineup = [...prevState.home_lineup];
        //         home_lineup[home_lineup.indexOf(oldData)] = newData;
        //         if (pitcherChange === true){
        //             count = 0;
        //             home_lineup.forEach(row => {
        //                 if (count !== home_lineup.indexOf(oldData)){
        //                     if (row.startingPitcher === true){
        //                         row.changed = true;
        //                     }
        //                     row.startingPitcher = false;
        //                 }
        //                 count ++;
        //             })
        //         }
        //         return { ...prevState, home_lineup };
        //     }
        // });
      }
      // this.setState({age: newAge});
    }

    pullInjury(game_id) {
      const config = {
        headers: {
          Authorization: `Bearer ${this.state.accessToken}`,
        },
      };
      var url =
        "https://consoleproxy.sportsiq.ai/siqTools/injurySystem?method=get&game_id=" +
        game_id;
      axios
        .get(url, config)
        .then((res) => {
          var injuries = res.data.data;
          var away_injury = [];
          var home_injury = [];
          var total = injuries.length;
          var count = 0;
          injuries.forEach((player) => {
            if (player.return_on) {
              player.return_on = moment
                .utc(player.return_on)
                .format("YYYY-MM-DD");
            }
            if (player.is_away) {
              away_injury.push(player);
            } else {
              home_injury.push(player);
            }
            count++;
            if (count === total) {
              this.setState({ away_injury: away_injury });
              this.setState({ home_injury: home_injury });
            }
          });
        })
        .catch((error) => {
          console.log("siq control log pulling error");
        });
    }

    async componentDidMount() {
      this.checkAuthentication(true);
      if (this.state.accessToken) {
        this.pullInjury(this.props.game.match.params.id);
      }
    }

    async componentDidUpdate(prevProps, prevState) {
      this.checkAuthentication(false);
      var selectedGame;
      this.props.bootstrap.forEach((league) => {
        if (league.league.name === this.props.game.match.params.sports) {
          league.games.forEach((game) => {
            if (game.id === this.props.game.match.params.id) {
              selectedGame = game;
            }
          });
        }
      });
      if (prevProps.game.match.params.id !== this.props.game.match.params.id) {
        this.setState({ loading: true });
        this.setState({ mute: false });
        this.props.messageTrigger(
          "game_bootstrap",
          this.props.game.match.params.id,
          this.props.game.match.params.date
        );
        // this.pullRankings(
        //   this.props.game.match.params.id,
        //   selectedGame.away.id,
        //   "away"
        // );
        // this.pullRankings(
        //   this.props.game.match.params.id,
        //   selectedGame.home.id,
        //   "home"
        // );
        this.pullInjury(this.props.game.match.params.id);
      }

      var awayPlayers = [];
      var homePlayers = [];
      var parsedPlayers = [];
      var fetchedData;
      if (
        selectedGame &&
        selectedGame.details &&
        selectedGame.details.lineups
      ) {
        if (this.props.game.match.params.sports === "MLB") {
          fetchedData = selectedGame.details.lineups;

          fetchedData.forEach((player) => {
            var order = player.battingOrder;
            if (player.battingOrder === 0) {
              order = 10;
            } else if (player.battingOrder === -1) {
              order = 99;
            }
            if (player.active !== true) {
              order = 100;
            }

            var parsedPlayer = {
              playerId: player.playerId,
              name: player.firstName + " " + player.lastName,
              sr_player_id: player.srPlayerId,
              team: player.teamName,
              position: player.position === "P" ? "Pitcher" : player.position,
              side: player.side,
              active: player.active,
              starPlayer: player.starPlayer,
              gameId: player.gameId,
              startingPitcher: player.active ? player.startingPitcher : false,
              battingOrder: order,
              changed: false,
            };
            parsedPlayers.push(parsedPlayer);
          });

          parsedPlayers.forEach((player) => {
            if (player.side === "home") {
              homePlayers.push(player);
            } else {
              awayPlayers.push(player);
            }
          });
        } else {
          fetchedData = selectedGame.details.lineups;

          fetchedData.forEach((player) => {
            var parsedPlayer = {
              playerId: player.playerId,
              name: player.firstName + " " + player.lastName,
              sr_player_id: player.srPlayerId,
              position: player.position,
              team: player.teamName,
              side: player.side,
              active: player.active,
              starPlayer: player.starPlayer,
              questionable: player.questionable,
              show: player.show,
              probable: player.probable,
              block: player.block,
              gameId: player.gameId,
              // order: player.active ? 0 : 1
            };

            var newOrder;
            var if_toggled = 0;
            if (this.props.game.match.params.sports !== "MLB") {
              if (player.active === true) {
                newOrder = 1;
              } else if (player.probable === true) {
                newOrder = 2;
              } else if (player.questionable === true) {
                newOrder = 3;
              } else if (
                player.active === false &&
                player.questionable === false &&
                player.questionable === false
              ) {
                newOrder = 4;
                if_toggled = 1;
              }
            } else {
              newOrder = player.active ? 1 : 2;
            }
            parsedPlayer.order = newOrder;

            if (
              this.props.game.match.params.sports === "NFL" ||
              this.props.game.match.params.sports === "NHL" || 
              this.props.game.match.params.sports === "CFB"
            ) {
              parsedPlayer.if_toggled = if_toggled;
            }
            parsedPlayers.push(parsedPlayer);
          });

          var order;
          if (this.props.game.match.params.sports === "NFL" || this.props.game.match.params.sports === "CFB") {
            order = ["K", "TE", "WR", "RB", "QB"];
            parsedPlayers.sort(
              firstBy("if_toggled")
                .thenBy(function (a, b) {
                  return order.indexOf(b.position) - order.indexOf(a.position);
                })
                .thenBy("order")
            );
          }
          if (this.props.game.match.params.sports === "NHL") {
            order = ["D", "F", "G"];
            parsedPlayers.sort(
              firstBy("if_toggled")
                .thenBy(function (a, b) {
                  return order.indexOf(b.position) - order.indexOf(a.position);
                })
                .thenBy("order")
            );
          }
          parsedPlayers.forEach((player) => {
            if (player.side === "home") {
              homePlayers.push(player);
            } else {
              awayPlayers.push(player);
            }
          });
        }
      }

      var newAwayLineups = [];
      var newHomeLineups = [];
      JSON.parse(JSON.stringify(this.state.away_lineup)).forEach((player) => {
        delete player.tableData;
        newAwayLineups.push(player);
      });
      JSON.parse(JSON.stringify(this.state.home_lineup)).forEach((player) => {
        delete player.tableData;
        newHomeLineups.push(player);
      });

      if (prevState.away_lineup.length < 1) {
        if (awayPlayers.length > 0) {
          this.setState({ away_lineup: awayPlayers });
          this.setState({ home_lineup: homePlayers });
          this.setState({ cache_away_lineup: awayPlayers });
          this.setState({ cache_home_lineup: homePlayers });
        }
      } else {
        if (
          prevProps.gamestrapLoading !== this.props.gamestrapLoading &&
          this.props.gamestrapLoading === true
        ) {
          this.setState({ away_lineup: [] });
          this.setState({ home_lineup: [] });
        }
        if (
          prevProps.game.match.params.id !== this.props.game.match.params.id
        ) {
          this.setState({ away_lineup: awayPlayers });
          this.setState({ home_lineup: homePlayers });
        } else if (
          prevProps.gamestrapLoading === true &&
          this.props.gamestrapLoading === false
        ) {
          this.setState({ away_lineup: awayPlayers });
          this.setState({ home_lineup: homePlayers });
        } else {
          if (prevState.loading === true) {
            this.setState({ loading: false });
            if (
              JSON.stringify(awayPlayers) !== JSON.stringify(newAwayLineups)
            ) {
              for (var i = 0; i < awayPlayers.length; i++) {
                awayPlayers[i].tableData = {};
                awayPlayers[i].tableData.id = i;
              }

              this.setState({ away_lineup: awayPlayers });
            }
            if (
              JSON.stringify(homePlayers) !== JSON.stringify(newHomeLineups)
            ) {
              for (var i = 0; i < homePlayers.length; i++) {
                homePlayers[i].tableData = {};
                homePlayers[i].tableData.id = i;
              }

              this.setState({ home_lineup: homePlayers });
            }
          }
        }
      }
    }

    ScoreSwitch(props) {
      return (
        <Switch
          size="small"
          checked={this.state[props.source]}
          onChange={(e) => {
            this.setState({ [props.source]: !this.state[props.source] });
            var newStatus = !this.state[props.source];
            console.log(newStatus);
          }}
        />
      );
    }

    ScoreBoard(props) {
      var awayColor = colorPicker(
        props.selectedGame.away.abbr,
        props.selectedLeague
      );
      var homeColor = colorPicker(
        props.selectedGame.home.abbr,
        props.selectedLeague
      );
      return (
        <div
          style={{
            display: "inline-block",
            margin: "5px 5px",
            fontFamily: "roboto, sans-serif",
          }}
        >
          <div style={{ textAlign: "center", display: "inline-block" }}>
            <div>
              <div
                style={{
                  borderTopLeftRadius: "8px",
                  background: awayColor,
                  display: "inline-block",
                  verticalAlign: "middle",
                  fontWeight: "bold",
                  fontSize: "22px",
                  minWidth: "60px",
                  padding: "9.5px 5px",
                }}
              >
                {props.selectedGame.away.abbr}
              </div>
              <div style={{ display: "inline-block" }}>
                <TeamLogo
                  color={awayColor}
                  team={props.selectedGame.away.abbr}
                  sports={props.selectedLeague}
                  source="game"
                  direction="right"
                />
              </div>
            </div>
            <div
              className={
                props.possession === "away"
                  ? "PossessionStyle"
                  : "noPossessionStyle"
              }
            ></div>
          </div>
          <div style={{ textAlign: "center", display: "inline-block" }}>
            <div
              style={{
                height: "50px",
                minWidth: "113px",
                display: "inline-block",
                verticalAlign: "middle",
                fontWeight: 600,
              }}
            >
              <div>{moment(this.props.game.scheduled).format("ddd H:mm")}</div>
              <div>{moment().tz(moment.tz.guess()).format("z")}</div>
              <div style={{ textAlign: "center" }}>
                <this.ScoreSwitch source={props.source} />
              </div>
            </div>
            <div
              className={
                props.possession === "neutral"
                  ? "PossessionStyle"
                  : "noPossessionStyle"
              }
            ></div>
          </div>
          <div style={{ textAlign: "center", display: "inline-block" }}>
            <div>
              <div style={{ display: "inline-block" }}>
                <TeamLogo
                  color={homeColor}
                  team={props.selectedGame.home.abbr}
                  sports={props.selectedLeague}
                  source="game"
                  direction="left"
                />
              </div>
              <div
                style={{
                  borderTopRightRadius: "8px",
                  background: homeColor,
                  display: "inline-block",
                  verticalAlign: "middle",
                  fontWeight: "bold",
                  fontSize: "22px",
                  minWidth: "60px",
                  padding: "9.5px 5px",
                }}
              >
                {props.selectedGame.home.abbr}
              </div>
            </div>
            <div
              className={
                props.possession === "home"
                  ? "PossessionStyle"
                  : "noPossessionStyle"
              }
            ></div>
          </div>
        </div>
      );
    }

    GameDrawer(props) {
      if (props.leagues.length > 0) {
        return (
          <div>
            <div style={{ height: "64px" }}></div>
            {props.leagues.map((league) => (
              <div key={league.league.id} style={{ color: "white" }}>
                <LeagueGameList
                  games={league.games}
                  league={league.league}
                  selectedGame={props.selectedGame}
                />
              </div>
            ))}
          </div>
        );
      } else {
        return null;
      }
    }

    MuteSwitch(props) {
      return (
        <div style={{ display: "inline-block", marginRight: "20px" }}>
          <Switch
            size="small"
            checked={this.state.mute}
            onChange={(e) => {
              this.setState({ mute: !this.state.mute });
            }}
          />
          Mute Lineup Notifications
        </div>
      );
    }

    TestLineup(props) {
      return (
        <Button
          id={E2EASelectors.MAIN_PAGE.PUBLISH_LINEUPS_TO_DEV_BUTTON}
          style={{
            color: "white",
            background: "#3f51b5",
            display: "inline-block",
          }}
          onClick={() => {
            const config = {
              headers: {
                Authorization: `Bearer ${props.accessToken}`,
              },
            }
            var url =
              "https://us-central1-lunar-brace-213823.cloudfunctions.net/sportsiq_http_proxy2/testLineupMessage?topic=sportsiq.message.dashboard.dev.staging&gameId=" +
              props.game_id;
            axios
              .get(url)
              .then((res) => {
                if (res.status !== 200) {
                  console.log(res);
                }
              })
              .catch((error) => {
                console.log(error);
              });
          }}
        >
          Publish Lineups to Dev
        </Button>
      );
    }

    RePublisher(props) {
      return (
        <div style={{ display: "inline-block", marginRight: "20px" }}>
          <Button
            id={E2EASelectors.MAIN_PAGE.REPUBLISH_ALL_LINEUPS_BUTTON}
            variant="contained"
            color="secondary"
            onClick={() => {
              props.leagueGames.forEach((game) => {
                var command = {};
                command.command = "game_lineups_label";
                command.game_id = game.id;
                command.status = "revealed";
                // console.log(command);
                this.publishPostLineup(command);
              });
            }}
          >
            Re-Publish all {props.selectedLeague} lineups of the day
          </Button>
        </div>
      );
    }

    Dashboard(props) {
      const { container } = props;
      const classes = useStyles();
      const theme = useTheme();
      const [mobileOpen, setMobileOpen] = React.useState(false);

      const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
      };

      return (
        <div>
          <div>
            <CssBaseline />
            <AppBar color="primary" className={classes.appBar}>
              <Toolbar>
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  edge="start"
                  onClick={handleDrawerToggle}
                  className={classes.menuButton}
                >
                  <MenuIcon />
                </IconButton>
                {/* <Link to={`/`} date={{key: "valueee222"}}>  */}
                <Link
                  to={{
                    pathname: `/`,
                    query: {
                      date: props.selectedGame
                        ? moment(props.selectedGame.scheduled).format(
                            "YYYY-MM-DD"
                          )
                        : "noDatee",
                    },
                  }}
                >
                  <img alt="" src={siqLogo} height="35" />
                </Link>
              </Toolbar>
            </AppBar>
          </div>
          <div style={{ height: "64px" }}></div>
          <div
            id="containerElement"
            style={{
              display: "flex",
              overflow: "auto",
              height: containerHeight,
            }}
          >
            <nav className={classes.drawer} aria-label="mailbox folders">
              {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
              <Hidden mdUp implementation="css">
                <Drawer
                  container={container}
                  variant="temporary"
                  anchor={theme.direction === "rtl" ? "right" : "left"}
                  open={mobileOpen}
                  onClose={handleDrawerToggle}
                  classes={{
                    paper: classes.drawerPaper,
                  }}
                  ModalProps={{
                    keepMounted: true, // Better open performance on mobile.
                  }}
                >
                  <this.GameDrawer
                    leagues={props.leagues}
                    selectedGame={props.selectedGame}
                  />
                </Drawer>
              </Hidden>
              <Hidden smDown implementation="css">
                <Drawer
                  classes={{
                    paper: classes.drawerPaper,
                  }}
                  variant="permanent"
                  open
                >
                  <this.GameDrawer
                    leagues={props.leagues}
                    selectedGame={props.selectedGame}
                  />
                </Drawer>
              </Hidden>
            </nav>
            <Container maxWidth="xl" style={{ marginTop: "12px" }}>
              {props.selectedLeague ? (
                <div style={{ textAlign: "center" }}>
                  {/* <this.ScoreBoard leagues={props.leagues} selectedLeague={props.selectedLeague} selectedGame={props.selectedGame} source="source1" possession="home"/>
                                    <this.ScoreBoard leagues={props.leagues} selectedLeague={props.selectedLeague} selectedGame={props.selectedGame} source="source2" possession="neutral"/> */}
                </div>
              ) : null}
              {props.selectedGame ? (
                <div>
                  <GameStatusControl
                    game_id={this.props.game.match.params.id}
                    selectedLeague={props.selectedLeague}
                  />
                </div>
              ) : null}
              {props.selectedGame ? (
                <div>
                  <TraderList
                    game_id={this.props.game.match.params.id}
                    selectedLeague={props.selectedLeague}
                  />
                </div>
              ) : null}
              {/* {props.selectedGame? 
                                <div>
                                    <div>
                                        <CheckList selectedGame={props.selectedGame} game_id={this.props.game.match.params.id} key={uuid()}/>
                                    </div>
                                </div>:
                                null
                            } */}
              {/* {props.selectedLeague === 'MLB'?
                                <div>
                                    <div>
                                        <MLBCheckList selectedGame={props.selectedGame} game_id={this.props.game.match.params.id} key={uuid()}/>
                                    </div>
                                </div>:
                                null
                            } */}
              {/* {props.selectedLeague === 'NBA' || props.selectedLeague === 'NHL'?
                                <GamePeriodTable />:
                                null
                            } */}
              {/* <TeamTable /> */}
              {/* {props.selectedLeague === 'NBA' || props.selectedLeague === 'WNBA' ?
                                <div>
                                    <div className="lineupStyle">
                                        <LineupTable data={this.state.away_lineup} selectedLeague={props.selectedLeague} game_id={this.props.game.match.params.id} title={'Away Lineup'} sendCommand={this.props.sendCommand} publishPostLineup={this.publishPostLineup} updateHandler={this.updateHandler} pullLineup={this.pullLineup} submitLineup={this.submitLineup} key={uuid()}/>
                                    </div>
                                    <div className="lineupStyle">
                                        <LineupTable data={this.state.home_lineup} selectedLeague={props.selectedLeague} game_id={this.props.game.match.params.id} title={'Home Lineup'} sendCommand={this.props.sendCommand} publishPostLineup={this.publishPostLineup} updateHandler={this.updateHandler} pullLineup={this.pullLineup} submitLineup={this.submitLineup} key={uuid()}/>
                                    </div>
                                </div>:
                                null
                            } */}
              <BroadCaster game={props} accessToken={this.state.accessToken} />
              <div>
                <this.SettlementSelector
                  league={props.selectedLeague}
                  game={props.selectedGame}
                />
                <this.MuteSwitch />
                <this.RePublisher
                  leagueGames={props.leagueGames}
                  selectedLeague={props.selectedLeague}
                />
                <this.TestLineup accessToken={this.state.accessToken} game_id={this.props.game.match.params.id} />
              </div>
              <div>
                <this.ShadingSelector
                  league={props.selectedLeague}
                  game={props.selectedGame}
                />
              </div>

              {props.selectedLeague === "NBA" ||
              props.selectedLeague === "WNBA" ? (
                <div>
                  <div
                    id={E2EASelectors.MAIN_PAGE.AWAY_LINEUP_PANEL.LINEUP_TABLE}
                    className="lineupStyle"
                  >
                    <LineupTable
                      data={this.state.away_lineup}
                      away={true}
                      email={this.state.email}
                      accessToken={this.state.accessToken}
                      shading_market={this.state.shading_market}
                      game={props.selectedGame}
                      team_id={props.selectedGame.away.id}
                      selectedLeague={props.selectedLeague}
                      game_id={this.props.game.match.params.id}
                      title={"Away Lineup"}
                      sendCommand={this.props.sendCommand}
                      publishPostLineup={this.publishPostLineup}
                      updateHandler={this.updateHandler}
                      clearGameLineup={this.clearGameLineup}
                      pullLineup={this.pullLastGameLineup}
                      submitLineup={this.submitLineup}
                      key={uuid()}
                    />
                  </div>
                  <div
                    id={E2EASelectors.MAIN_PAGE.HOME_LINEUP_PANEL.LINEUP_TABLE}
                    className="lineupStyle"
                  >
                    <LineupTable
                      data={this.state.home_lineup}
                      away={false}
                      email={this.state.email}
                      accessToken={this.state.accessToken}
                      shading_market={this.state.shading_market}
                      game={props.selectedGame}
                      team_id={props.selectedGame.home.id}
                      selectedLeague={props.selectedLeague}
                      game_id={this.props.game.match.params.id}
                      title={"Home Lineup"}
                      sendCommand={this.props.sendCommand}
                      publishPostLineup={this.publishPostLineup}
                      updateHandler={this.updateHandler}
                      clearGameLineup={this.clearGameLineup}
                      pullLineup={this.pullLastGameLineup}
                      submitLineup={this.submitLineup}
                      key={uuid()}
                    />
                  </div>
                </div>
              ) : null}
              {props.selectedLeague === "NFL" ||
              props.selectedLeague === "CFB" ? (
                <div>
                  <div
                    id={E2EASelectors.MAIN_PAGE.AWAY_LINEUP_PANEL.LINEUP_TABLE}
                    className="lineupStyle"
                  >
                    <NFLLineupTable
                      data={this.state.away_lineup}
                      away={true}
                      email={this.state.email}
                      accessToken={this.state.accessToken}
                      shading_market={this.state.shading_market}
                      game={props.selectedGame}
                      team_id={props.selectedGame.away.id}
                      selectedLeague={props.selectedLeague}
                      game_id={this.props.game.match.params.id}
                      title={"Away Lineup"}
                      sendCommand={this.props.sendCommand}
                      publishPostLineup={this.publishPostLineup}
                      updateHandler={this.updateHandler}
                      clearGameLineup={this.clearGameLineup}
                      pullLineup={this.pullLastGameLineup}
                      submitLineup={this.submitLineup}
                      key={uuid()}
                    />
                  </div>
                  <div
                    id={E2EASelectors.MAIN_PAGE.HOME_LINEUP_PANEL.LINEUP_TABLE}
                    className="lineupStyle"
                  >
                    <NFLLineupTable
                      data={this.state.home_lineup}
                      away={false}
                      email={this.state.email}
                      accessToken={this.state.accessToken}
                      shading_market={this.state.shading_market}
                      game={props.selectedGame}
                      team_id={props.selectedGame.home.id}
                      selectedLeague={props.selectedLeague}
                      game_id={this.props.game.match.params.id}
                      title={"Home Lineup"}
                      sendCommand={this.props.sendCommand}
                      publishPostLineup={this.publishPostLineup}
                      updateHandler={this.updateHandler}
                      clearGameLineup={this.clearGameLineup}
                      pullLineup={this.pullLastGameLineup}
                      submitLineup={this.submitLineup}
                      key={uuid()}
                    />
                  </div>
                </div>
              ) : null}
              {/* {props.selectedLeague === "NFL" ||
              props.selectedLeague === "CFB" ? (
                <div>
                  <div
                    id={E2EASelectors.MAIN_PAGE.AWAY_LINEUP_PANEL.LINEUP_TABLE}
                    className="lineupStyle"
                  >
                    <NFLLineupTable
                      data={this.state.away_lineup}
                      away={true}
                      game={props.selectedGame}
                      team_id={props.selectedGame.away.id}
                      selectedLeague={props.selectedLeague}
                      game_id={this.props.game.match.params.id}
                      title={"Away Lineup"}
                      sendCommand={this.props.sendCommand}
                      publishPostLineup={this.publishPostLineup}
                      updateHandler={this.updateHandler}
                      clearGameLineup={this.clearGameLineup}
                      pullLineup={this.pullLastGameLineup}
                      submitLineup={this.submitLineup}
                      key={uuid()}
                    />
                  </div>
                  <div
                    id={E2EASelectors.MAIN_PAGE.AWAY_LINEUP_PANEL.RANKING_TABLE}
                    className="lineupStyle"
                  >
                    <NFLRankingTable
                      data={this.state.away_rankings}
                      away={true}
                      game={props.selectedGame}
                      team_id={props.selectedGame.away.id}
                      selectedLeague={props.selectedLeague}
                      game_id={this.props.game.match.params.id}
                      title={"Away Rankings"}
                      sendCommand={this.props.sendCommand}
                      publishPostLineup={this.publishPostLineup}
                      updateHandler={this.updateHandler}
                      clearGameLineup={this.clearGameLineup}
                      pullLineup={this.pullLastGameLineup}
                      pullRankings={this.pullRankings}
                      key={uuid()}
                    />
                  </div>
                </div>
              ) : null}
              {props.selectedLeague === "NFL" ||
              props.selectedLeague === "CFB" ? (
                <div>
                  <div
                    id={E2EASelectors.MAIN_PAGE.HOME_LINEUP_PANEL.LINEUP_TABLE}
                    className="lineupStyle"
                  >
                    <NFLLineupTable
                      data={this.state.home_lineup}
                      away={false}
                      game={props.selectedGame}
                      team_id={props.selectedGame.home.id}
                      selectedLeague={props.selectedLeague}
                      game_id={this.props.game.match.params.id}
                      title={"Home Lineup"}
                      sendCommand={this.props.sendCommand}
                      publishPostLineup={this.publishPostLineup}
                      updateHandler={this.updateHandler}
                      clearGameLineup={this.clearGameLineup}
                      pullLineup={this.pullLastGameLineup}
                      submitLineup={this.submitLineup}
                      key={uuid()}
                    />
                  </div>
                  <div
                    id={E2EASelectors.MAIN_PAGE.HOME_RANKING_TABLE}
                    className="lineupStyle"
                  >
                    <NFLRankingTable
                      data={this.state.home_rankings}
                      away={false}
                      game={props.selectedGame}
                      team_id={props.selectedGame.home.id}
                      selectedLeague={props.selectedLeague}
                      game_id={this.props.game.match.params.id}
                      title={"Home Rankings"}
                      sendCommand={this.props.sendCommand}
                      publishPostLineup={this.publishPostLineup}
                      updateHandler={this.updateHandler}
                      clearGameLineup={this.clearGameLineup}
                      pullLineup={this.pullLastGameLineup}
                      pullRankings={this.pullRankings}
                      key={uuid()}
                    />
                  </div>
                </div>
              ) : null} */}
              {props.selectedLeague === "NHL" ? (
                <div>
                  <div
                    id={E2EASelectors.MAIN_PAGE.AWAY_LINEUP_PANEL.LINEUP_TABLE}
                    className="lineupStyle"
                  >
                    <NHLLineupTable
                      data={this.state.away_lineup}
                      away={true}
                      email={this.state.email}
                      accessToken={this.state.accessToken}
                      shading_market={this.state.shading_market}
                      game={props.selectedGame}
                      team_id={props.selectedGame.away.id}
                      selectedLeague={props.selectedLeague}
                      game_id={this.props.game.match.params.id}
                      title={"Away Lineup"}
                      sendCommand={this.props.sendCommand}
                      publishPostLineup={this.publishPostLineup}
                      updateHandler={this.updateHandler}
                      clearGameLineup={this.clearGameLineup}
                      pullLineup={this.pullLastGameLineup}
                      submitLineup={this.submitLineup}
                      key={uuid()}
                    />
                  </div>
                  <div
                    id={E2EASelectors.MAIN_PAGE.HOME_LINEUP_PANEL.LINEUP_TABLE}
                    className="lineupStyle"
                  >
                    <NHLLineupTable
                      data={this.state.home_lineup}
                      away={false}
                      email={this.state.email}
                      accessToken={this.state.accessToken}
                      shading_market={this.state.shading_market}
                      game={props.selectedGame}
                      team_id={props.selectedGame.home.id}
                      selectedLeague={props.selectedLeague}
                      game_id={this.props.game.match.params.id}
                      title={"Home Lineup"}
                      sendCommand={this.props.sendCommand}
                      publishPostLineup={this.publishPostLineup}
                      updateHandler={this.updateHandler}
                      clearGameLineup={this.clearGameLineup}
                      pullLineup={this.pullLastGameLineup}
                      submitLineup={this.submitLineup}
                      key={uuid()}
                    />
                  </div>
                </div>
              ) : null}
              {props.selectedLeague === "MLB" ? (
                <div>
                  <div
                    id={E2EASelectors.MAIN_PAGE.AWAY_LINEUP_PANEL.LINEUP_TABLE}
                    className="lineupStyle"
                  >
                    <MLBLineupTable
                      data={this.state.away_lineup}
                      away={true}
                      email={this.state.email}
                      accessToken={this.state.accessToken}
                      shading_market={this.state.shading_market}
                      game={props.selectedGame}
                      team_id={props.selectedGame.away.id}
                      selectedLeague={props.selectedLeague}
                      game_id={this.props.game.match.params.id}
                      title={"Away Lineup"}
                      sendCommand={this.props.sendCommand}
                      publishPostLineup={this.publishPostLineup}
                      updateHandler={this.MLBupdateHandler}
                      clearGameLineup={this.clearGameLineup}
                      pullLineup={this.pullLastGameLineup}
                      submitLineup={this.MLBsubmitLineup}
                      key={uuid()}
                    />
                  </div>
                  <div
                    id={E2EASelectors.MAIN_PAGE.HOME_LINEUP_PANEL.LINEUP_TABLE}
                    className="lineupStyle"
                  >
                    <MLBLineupTable
                      data={this.state.home_lineup}
                      away={false}
                      email={this.state.email}
                      accessToken={this.state.accessToken}
                      shading_market={this.state.shading_market}
                      game={props.selectedGame}
                      team_id={props.selectedGame.home.id}
                      selectedLeague={props.selectedLeague}
                      game_id={this.props.game.match.params.id}
                      title={"Home Lineup"}
                      sendCommand={this.props.sendCommand}
                      publishPostLineup={this.publishPostLineup}
                      updateHandler={this.MLBupdateHandler}
                      clearGameLineup={this.clearGameLineup}
                      pullLineup={this.pullLastGameLineup}
                      submitLineup={this.MLBsubmitLineup}
                      key={uuid()}
                    />
                  </div>
                </div>
              ) : null}
              <div>
                <InjuryTables
                  selectedGame={props.selectedGame}
                  game_id={this.props.game.match.params.id}
                  away_injury={this.state.away_injury}
                  home_injury={this.state.home_injury}
                  selectedLeague={props.selectedLeague}
                  accessToken={this.state.accessToken}
                  key={uuid()}
                />
              </div>
              {/* <div>
                <ControlLogger
                  selectedGame={props.selectedGame}
                  game_id={this.props.game.match.params.id}
                  key={uuid()}
                />
              </div> */}
            </Container>
          </div>
        </div>
      );
    }

    render() {
      var selectedGame;
      var selectedLeague;
      var leagueGames = [];
      this.props.bootstrap.forEach((league) => {
        if (league.league.name === this.props.game.match.params.sports) {
          leagueGames = league.games;
          league.games.forEach((game) => {
            if (game.id === this.props.game.match.params.id) {
              selectedGame = game;
              selectedLeague = this.props.game.match.params.sports;
            }
          });
        }
      });

      return (
        <div>
          <this.Dashboard
            leagues={this.props.bootstrap}
            selectedGame={selectedGame}
            selectedLeague={selectedLeague}
            leagueGames={leagueGames}
          />
          {/* <LabelDial sendCommand={this.props.sendCommand} game={selectedGame}/> */}
        </div>
      );
    }
  }
);
